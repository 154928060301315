@import '_colours.scss';
@import '_fonts.scss';
body {
    font-family: $font;
    font-size: 16px;
    color: $font-colour;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}
.clear-float {
    clear:both;
}
h1 {
    font-size: 50px;
    margin-top:0;
}
.blue-highlight {
    color: $dark-blue;
    font-size: 20px;
    font-weight: 700;
}
.red-highlight {
    color: $red;
    font-size: 20px;
    font-weight: 700;
}
.red {
    color: $red;
}
.blue {
    color: $blue;
}
.dark-blue {
    color: $dark-blue;
}
.centre-align {
    text-align: center;
}
.button,
.file-browse {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding: 15px 20px;
    max-width: 100%;
    color: #FFF;
    font-size: 18px;
    font-weight: 700;
    background-color: $red;
    border: 1px solid $red;
    border-radius: 8px;
    box-sizing: border-box;
    transition: all 0.3s ease;
}
.file-browse {
    background-color: #FFF;
    border: 1px solid #000;
    color: #000;
    width: 100%;
    margin-bottom: 20px;
}
.button.blue {
    background-color: $blue;
    border: 1px solid $blue;
}
.button.dark-blue {
    background-color: $dark-blue;
    border: 1px solid $dark-blue;
}
.button:hover {
    color: #FFF;
    background-color: $dark-blue;
    border: 1px solid $dark-blue;
    cursor: pointer;
}
.button.blue:hover,
.button.dark-blue:hover {
    background-color: $red;
    border: 1px solid $red;
}
.pop-up form .button {
    max-width: 200px;
    display: block;
    margin: 0 auto;
}
img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}
img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}
.column-flex {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}
.three-col {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    padding: 1%;
    box-sizing: border-box;
    width: 30%;
}
@media only screen and (max-width: 760px) {
    h1 {
        font-size: 5vw;
    }
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
    .column-flex {
        flex-flow: column;
    }
    .three-col {
        width: 100%;
    }
}
@media only screen and (max-width: 550px) {
    h1 {
        font-size: 6vw;
    }
    .column-flex {
        flex-flow: column nowrap;
    }
    .three-col {
        width: 100%;
        padding: 0px;
    }
}