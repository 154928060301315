$font-colour: #4D4D4D;
$blue-overlay: #1c489eb3;
$services-overlay: #4c99d3d9;
$services-hover-overlay: #4c99d3f5;
$orange-overlay: #ff9933d1;
$orange-hover-overlay: #ff9933f0;
$dark-blue: #1C489E;
$blue: #4C99D3;
$red: #D47261;
$grey: #F0F0F0;
$dark-grey: #eae9e9;